import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Trans } from 'gatsby-plugin-react-i18next'
import {
  getQueryParams,
  useQueryParamString
} from 'react-use-query-param-string'

import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { scaleFromMapValues } from '../../utils/helpers'
import { useTranslation } from 'react-i18next'
import Map from './Map'
import MapLegend from './MapLegend'

const ChartTitle = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: ${({ color }) => `var(--${color})`};
`
const SelectorButton = styled.button`
  background-color: var(--brightBlue);
  border: none;
  color: white;
  padding: 0.3rem 1.2rem;
  text-align: left;
  text-decoration: none;
  font-size: 1.25rem;
  width: 250px;
  border-radius: 4px;
  &:hover {
    background-color: var(--baseBlue);
    ${Mq.sm} {
      background-color: var(--brightBlue);
      opacity: 0.9;
    }
  }
  &:focus {
    outline-offset: -2px;
  }
  &:after {
    content: '';
    position: relative;
    top: 1.2rem;
    left: 70%;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-top: 11px solid var(--white);
    cursor: pointer;
    ${Mq.sm} {
      left: 80%;
    }
  }
  ${Mq.sm} {
    width: 100%;
    padding: 0.5rem 1rem;
  }
`

const DropDownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: sticky;
  top: 3rem;
  z-index: 10;
  ${Mq.sm} {
    top: 5.5rem;
  }
`
const DropDown = styled.div`
  position: absolute;
  left: 0;
  width: 250px;
  background-color: var(--brightBlue);
  border-radius: 4px;
  display: ${(props) => (props.open ? 'block' : 'none')};
  padding-bottom: 1rem;
  ${Mq.sm} {
    width: 100%;
  }
`

const OptionButton = styled.button`
  background-color: var(--brightBlue);
  border: none;
  color: white;
  padding: 0.5rem 2rem;
  text-align: left;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: bold;
  width: 100%;
  &:hover {
    background-color: var(--baseBlue);
  }
  &:focus {
    outline-offset: -2px;
  }
`

const Disclaimer = styled.div`
  margin-top: 2.5rem;
  font-size: 1rem;
`

const SP12Chart = ({ data, region, forExport = false }) => {
  const { t } = useTranslation()
  const params = getQueryParams()
  const [isClient, setClient] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [colorScale, setColorScale] = useState(null)
  const [selectedYear, setSelectedYear] = useQueryParamString(
    'year',
    params.year
  )

  // const wrangleSP12Data = (data) => {
  //   return data
  //     .map((d) => {
  //       const geo = d.iSO3
  //       return Object.entries(d)
  //         .map(([key, value]) => {
  //           const keysToExclude = [
  //             'memberState',
  //             'iSO3',
  //             'id',
  //             'region',
  //             'regionType'
  //           ]
  //           if (
  //             !keysToExclude.includes(key) &&
  //             value !== null &&
  //             value !== -2 &&
  //             value !== -1 //-1 designates country does not measure this and -2 designates missing data
  //           ) {
  //             const year = +key.slice(-4)
  //             return { year, geo, value }
  //           } else return null
  //         })
  //         .filter((item) => item)
  //     })
  //     .flat()
  // }

  // Sort years in descending order
  const extractYears = (data) => {
    return Array.from(new Set(data.map((d) => d.YEAR))).sort((a, b) => b - a)
  }

  const availableYears = extractYears(data)

  // Most recent year by default
  useEffect(() => {
    setClient(true)
    if (!params.year) {
      setSelectedYear(availableYears[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Update of selected year
  useEffect(() => {
    if (data.length && isClient) {
      const filteredData = data.filter((d) => d.YEAR === Number(selectedYear))

      const colorScale = scaleFromMapValues(filteredData)
      setFilteredData(filteredData)
      setColorScale(() => colorScale)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, isClient, data])

  const handleMenu = () => {
    setOpen(!isOpen)
  }

  const handleOptionSelection = (option) => {
    setOpen(!isOpen)
    setSelectedYear(option)
  }

  return (
    <div style={{ margin: '5rem 0' }}>
      <ChartTitle color={'baseBlue'}>{`${t('year')}:`}</ChartTitle>
      <>
        <SelectorButton aria-haspopup="menu" onClick={handleMenu}>
          <Trans>{{ selectedYear }}</Trans>
        </SelectorButton>
        <DropDownContainer>
          <DropDown open={isOpen} title="menu to select a year" role="menu">
            {availableYears.map((year) => (
              <OptionButton
                key={year}
                onClick={() => handleOptionSelection(year)}
              >
                {year}
              </OptionButton>
            ))}
          </DropDown>
        </DropDownContainer>
      </>
      <Map
        data={filteredData}
        scale={colorScale}
        forExport={forExport}
        alt={t('Map showing density of physicians, nurses and midwives.')}
      />
      <MapLegend scale={colorScale} />
      <Disclaimer>{t('map-disclaimer')}</Disclaimer>
    </div>
  )
}

export default SP12Chart
