import React from 'react'
import Table from '../Table'
import { useTranslation } from 'react-i18next'

const SP12Table = ({ regions }) => {
  const { t } = useTranslation()
  // const yearsWithData = []
  // const wrangleData = () => {
  //   const allData = Object.entries(regions)
  //     .map(([key, value]) => {
  //       //gets the years that have data so we know what columns to make
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]
  //       if (!keysToExclude.includes(key)) {
  //         const year = +key.slice(-4)
  //         if (value) {
  //           yearsWithData.push(year)
  //           return { year, value }
  //         } else return null
  //       } else return null
  //     })
  //     .filter((item) => item)

  //   const formattedData = allData.reduce((acc, d) => {
  //     acc[d.year] = Math.round(d.value)
  //     return acc
  //   }, {})

  //   const completeFormattedData = [
  //     Object.assign(formattedData, {
  //       label: t('density of physicians, nurses/midwives')
  //     })
  //   ]

  //   return completeFormattedData
  // }
  let years = []
  const wrangleSp12TableData = (data) => {
    const formattedData = data.reduce((acc, d) => {
      years.push(d.YEAR)
      acc[d.YEAR] = Math.round(d.VALUE_NUM)
      acc['label'] = t('density of physicians, nurses/midwives')
      return acc
    }, {})

    return [formattedData]
  }
  const sp12Data = wrangleSp12TableData(regions)
  const data = React.useMemo(() => sp12Data, [sp12Data])

  const yearHeaders = years
    .sort((a, b) => (a > b ? 1 : -1))
    .map((year) => {
      return {
        Header: `${year}`,
        accessor: `${year}`
      }
    })

  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: '',
        accessor: 'label' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders])

  return (
    <Table
      data={data}
      columns={columns}
      description={t(
        'A table that shows the density of physicians, nurses and midwives.'
      )}
    />
  )
}

export default SP12Table
