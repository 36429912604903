import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { MAP_COLOR_PALETTE } from '../../utils/const'

const LEGEND_ITEM_WIDTH = 45
const LEGEND_ITEM_HEIGHT = 16
const MARGIN = 10

const Container = styled.div`
  display: flex;
  justify-content: center;
  ${Mq.sm} {
    flex-direction: column;
  }
`
const LegendItems = styled.div`
  display: flex;
  ${Mq.sm} {
    margin-bottom: ${LEGEND_ITEM_HEIGHT + MARGIN}px;
  }
`

const LegendItem = styled.div`
  width: ${LEGEND_ITEM_WIDTH}px;
  height: ${LEGEND_ITEM_HEIGHT}px;
  position: relative;
`

const LegendKey = styled.span`
  position: absolute;
  top: ${LEGEND_ITEM_HEIGHT}px;
  left: ${LEGEND_ITEM_WIDTH}px;
  white-space: nowrap;
  font-size: 0.875rem;
  &.left-aligned {
    left: 0;
  }
`

const MapLegend = ({ scale }) => {
  const { t } = useTranslation()
  const domain = scale?.domain()
  const range = scale?.range()
  // Prepare legend items
  const thresholds = domain?.map((item, idx) => {
    const color = range[idx]
    return { id: idx, label: idx === 0 ? item : item + 1, color }
  })

  return (
    <Container>
      <LegendItems>
        <LegendItem
          style={{
            backgroundColor: MAP_COLOR_PALETTE.NoData,
            marginRight: LEGEND_ITEM_WIDTH * 2
          }}
        >
          <LegendKey className="left-aligned">{t('No data')}</LegendKey>
        </LegendItem>
      </LegendItems>
      <LegendItems>
        {thresholds?.map(({ id, label, color }, idx) => {
          return (
            <LegendItem key={`item-${id}`} style={{ backgroundColor: color }}>
              {idx === 0 && <LegendKey className="left-aligned">0</LegendKey>}
              {idx !== thresholds.length - 1 && <LegendKey>{label}</LegendKey>}
            </LegendItem>
          )
        })}
      </LegendItems>
    </Container>
  )
}

export default MapLegend
