import React from 'react'
import PageHeader from '../components/PageHeader'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import Header from '../components/Header'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
import Notes from '../components/Notes'
import Footer from '../components/Footer'
import ImageExport from '../components/ImageExport'
import SP12Chart from '../components/SP12/Sp12Chart'
import SP12Table from '../components/SP12/SP12Table'
import {
  LOCATION_LABELS,
  INCOME_GROUPS,
  UNI_REGIONS,
  WHO_REGIONS,
  WHO_LOCATION_OPTIONS,
  INC_LOCATION_OPTIONS,
  UNI_LOCATION_OPTIONS,
  REGION_LOOKUP
} from '../utils/const'
//import InsufficientData from '../components/InsufficientData'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'
import RegionInfo from '../components/RegionInfo'
import Disclaimer from '../components/Disclaimer'

const Sp12Page = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { sectionId } = pageContext
  const { content, footerContent } = data

  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')

  //which region are we on?
  const isInc = INCOME_GROUPS.includes(reg)
  const isUni = UNI_REGIONS.includes(reg)
  const isWho = WHO_REGIONS.includes(reg)
  const isGbl = reg === 'GBL' || !reg
  const regionLabel = isGbl ? t('global') : t(LOCATION_LABELS[reg])

  //which countries are in this region?
  const filterData = (data) => {
    return isInc
      ? data.filter((d) => d.WB_INCOME_STATUS === REGION_LOOKUP[reg])
      : isUni
      ? data.filter((d) => d.UNICEF_REGION === REGION_LOOKUP[reg])
      : isWho
      ? data.filter((d) => d.WHO_REGION === REGION_LOOKUP[reg])
      : data
  }

  const mapData = filterData(data.mapData.nodes)

  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      {/* <Header content={content} /> */}
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS}
          incOptions={INC_LOCATION_OPTIONS}
          uniOptions={UNI_LOCATION_OPTIONS}
        />
        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>

        <>
          <ImageExport
            title={`${content.chartTitle}—${regionLabel}`}
            dataSource={content.dataSourceNotesRt}
          >
            <SP12Chart data={mapData} forExport={true} />
          </ImageExport>
          <SP12Chart data={mapData}></SP12Chart>

          <SP12Table
            regions={data.regions.nodes.filter(
              (t) => t.CODE === REGION_LOOKUP[reg]
            )}
            // regions={
            //   isGbl
            //     ? data.global
            //     : isInc
            //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
            //     : isUni
            //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
            //     : data.regions.nodes.find((d) => d.iSO3 === reg)
            // }
          />
        </>
        {!isGbl && (isUni || isInc) && <Disclaimer />}
        {!isGbl && <RegionInfo reg={reg} />}
      </PageContent>
      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Sp12Page

// iso3 is null for global page but this works because it is the only null one
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleSp12CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }
    mapData: allCountries(filter: { IA2030_IND: { eq: "SP1.2" } }) {
      nodes {
        COUNTRY
        COUNTRY_NAME
        YEAR
        INDCODE
        VALUE_NUM
        WHO_REGION
        UNICEF_REGION
        WB_INCOME_STATUS
      }
    }
    regions: allRegions(filter: { IA2030_IND: { eq: "SP1.2" } }) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        VALUE_NUM
        YEAR
      }
    }
  }
`
// mapData: allGoogleSp12Sheet(filter: { regionType: { eq: "CTY" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     phyNurseMidwive2020
//     phyNurseMidwive2021
//     phyNurseMidwive2022
//     phyNurseMidwive2023
//     phyNurseMidwive2024
//     phyNurseMidwive2025
//     phyNurseMidwive2026
//     phyNurseMidwive2027
//     phyNurseMidwive2028
//     phyNurseMidwive2029
//     phyNurseMidwive2030
//   }
// }
// global: googleSp12Sheet(regionType: { eq: "GBL" }) {
//   id
//   iSO3
//   region
//   memberState
//   phyNurseMidwive2020
//   phyNurseMidwive2021
//   phyNurseMidwive2022
//   phyNurseMidwive2023
//   phyNurseMidwive2024
//   phyNurseMidwive2025
//   phyNurseMidwive2026
//   phyNurseMidwive2027
//   phyNurseMidwive2028
//   phyNurseMidwive2029
//   phyNurseMidwive2030
// }
// regions: allGoogleSp12Sheet(filter: { regionType: { eq: "REG" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     phyNurseMidwive2020
//     phyNurseMidwive2021
//     phyNurseMidwive2022
//     phyNurseMidwive2023
//     phyNurseMidwive2024
//     phyNurseMidwive2025
//     phyNurseMidwive2026
//     phyNurseMidwive2027
//     phyNurseMidwive2028
//     phyNurseMidwive2029
//     phyNurseMidwive2030
//   }
// }
// income: allGoogleSp12Sheet(filter: { regionType: { eq: "INC" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     incomeGroup
//     phyNurseMidwive2020
//     phyNurseMidwive2021
//     phyNurseMidwive2022
//     phyNurseMidwive2023
//     phyNurseMidwive2024
//     phyNurseMidwive2025
//     phyNurseMidwive2026
//     phyNurseMidwive2027
//     phyNurseMidwive2028
//     phyNurseMidwive2029
//     phyNurseMidwive2030
//   }
// }
// unicef: allGoogleSp12Sheet(filter: { regionType: { eq: "UNI" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     uNICEFRegion
//     phyNurseMidwive2020
//     phyNurseMidwive2021
//     phyNurseMidwive2022
//     phyNurseMidwive2023
//     phyNurseMidwive2024
//     phyNurseMidwive2025
//     phyNurseMidwive2026
//     phyNurseMidwive2027
//     phyNurseMidwive2028
//     phyNurseMidwive2029
//     phyNurseMidwive2030
//   }
// }
