import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'react-tippy'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import OlMap from 'ol/Map'
import View from 'ol/View'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import TopoJSON from 'ol/format/TopoJSON'
import GeoJSON from 'ol/format/GeoJSON'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { defaults as defaultInteractions } from 'ol/interaction.js'
import { MAP_COLOR_PALETTE, FEATURE_PROP, COUNTRIES } from '../../utils/const'

import 'ol/ol.css'
import 'react-tippy/dist/tippy.css'

const isBrowser = typeof window !== 'undefined'

// Shape files

//switched to geojson here.
//Had to edit the topojson file in Mapshaper in order to separate the different territories of France and Norway,
//i.e. French Guiana from France and Svalbard from Norway.
//When I exported from Mapshaper, Open Layers map didn't show some countries (Chile, Argentina, Indonesia)
//even though they were in topojson file.
//So exported as geojson instead and it worked.
//Only using the countries layers because disputed territories are only seen in outline form.

//update: switched back to topojson because it's smaller and faster. But had to take geojson file into mapshaper and export as topojson
//const ADMIN_GEOJSON = 'shapes/WB_countries_v4_geo.json'
const ADMIN_TOPOJSON = '/shapes/WB_countries_v4_geo_to_topo.json'
//const ADMIN_TOPOJSON = '/shapes/WB_countries_v3_edit_topo.json' -- this is only partially edited but some coutries don't work
//const ADMIN_TOPOJSON = '/shapes/WB_countries_v3_topo.json'

const DISPUTED_SHAPES_GEOJSON =
  '/shapes/WB_disputed_areas_Admin0_10m_lowres-geo.json'

const DISPUTED_LINES_GEOJSON =
  '/shapes/WB_Adm0_boundary_lines_disputed_areas_10m_lowres-geo.json'

const ADMIN_LINES_GEOJSON = '/shapes/WB_Adm0_boundary_lines_10m_lowres-geo.json'

// Styles
const STYLE = new Style({
  fill: new Fill({
    color: MAP_COLOR_PALETTE.NoData
  })
})

const DISPUTED_SHAPES_STYLE = new Style({
  fill: new Fill({
    color: MAP_COLOR_PALETTE.DisputedAreas
  }),
  stroke: new Stroke({
    color: MAP_COLOR_PALETTE.DisputedLines,
    width: 0.5
  })
})

const DISPUTED_LINES_STYLE = new Style({
  stroke: new Stroke({
    color: MAP_COLOR_PALETTE.DisputedLines,
    width: 0.5,
    lineDash: [4, 4]
  })
})

const ADMIN_LINES_STYLE = new Style({
  stroke: new Stroke({
    color: '#FFFFFF',
    width: 1
  })
})

// Sources
// const VECTOR_SOURCE = new VectorSource({
//   url: ADMIN_GEOJSON,
//   format: new GeoJSON()
// })

const VECTOR_SOURCE = new VectorSource({
  url: ADMIN_TOPOJSON,
  format: new TopoJSON({
    layers: ['countries', 'disputed']
    //layers: ['collection']
  }),
  overlaps: false
})

const DISPUTED_SHAPES_SOURCE = new VectorSource({
  url: DISPUTED_SHAPES_GEOJSON,
  format: new GeoJSON()
})

const DISPUTED_LINES_SOURCE = new VectorSource({
  url: DISPUTED_LINES_GEOJSON,
  format: new GeoJSON()
})

const ADMIN_LINES_SOURCE = new VectorSource({
  url: ADMIN_LINES_GEOJSON,
  format: new GeoJSON()
})

// Layers
const VECTOR_LAYER = new VectorLayer({
  source: VECTOR_SOURCE,
  style: STYLE
})

const DISPUTED_SHAPES_VECTOR_LAYER = new VectorLayer({
  source: DISPUTED_SHAPES_SOURCE,
  style: DISPUTED_SHAPES_STYLE
})

const DISPUTED_LINES_VECTOR_LAYER = new VectorLayer({
  source: DISPUTED_LINES_SOURCE,
  style: DISPUTED_LINES_STYLE
})

const ADMIN_LINES_VECTOR_LAYER = new VectorLayer({
  source: ADMIN_LINES_SOURCE,
  style: ADMIN_LINES_STYLE
})

const EXPORT_VECTOR_LAYER = new VectorLayer({
  source: VECTOR_SOURCE,
  style: STYLE
})

let MAP_OPTIONS = null
let MOBILE_OPTION_OVERRIDES = null
let EXPORT_MAP_OPTIONS = null
// Required for SSR
if (isBrowser) {
  MAP_OPTIONS = {
    view: new View({
      projection: 'EPSG:4326',
      center: [0, 0], // Specified in lon/lat coords (EPSG:4326)
      zoom: 0,
      multiWorld: false,
      enableRotation: false,
      extent: [
        // minX, minY, maxX, maxY.
        -171.05, -140, 191.05, 150
      ]
    }),
    interactions: defaultInteractions({
      doubleClickZoom: false,
      dragPan: false,
      mouseWheelZoom: false
    }),
    controls: [], // Removes zooming controls
    layers: [
      VECTOR_LAYER,
      ADMIN_LINES_VECTOR_LAYER,
      DISPUTED_SHAPES_VECTOR_LAYER,
      DISPUTED_LINES_VECTOR_LAYER
    ]
  }

  MOBILE_OPTION_OVERRIDES = {
    view: new View({
      projection: 'EPSG:4326',
      center: [0, 0],
      zoom: 0,
      multiWorld: false,
      enableRotation: false,
      extent: [
        // minX, minY, maxX, maxY.
        -250, -250, 250, 250
      ]
    })
  }

  EXPORT_MAP_OPTIONS = {
    view: new View({
      projection: 'EPSG:4326',
      center: [0, 0],
      zoom: 0,
      multiWorld: false,
      enableRotation: false,
      extent: [
        // minX, minY, maxX, maxY.
        -171.05, -140, 191.05, 150
      ]
    }),
    interactions: defaultInteractions({
      doubleClickZoom: false,
      // dragAndDrop: false,
      dragPan: false,
      // keyboardPan: false,
      // keyboardZoom: false,
      mouseWheelZoom: false
    }),
    layers: [
      EXPORT_VECTOR_LAYER,
      ADMIN_LINES_VECTOR_LAYER,
      DISPUTED_SHAPES_VECTOR_LAYER,
      DISPUTED_LINES_VECTOR_LAYER
    ],
    controls: []
  }
}

const Container = styled.div`
  width: 100%;
  height: 600px;
  margin-top: 10px;
`

const TooltipContainer = styled.div`
  text-align: left;

  .map-tooltip-heading {
    font-size: 0.75rem;
    letter-spacing: 0.96px;
    text-transform: uppercase;
  }
  .map-tooltip-value {
    font-size: 1.375;
    font-weight: bold;
  }

  .map-tooltip-message {
    font-size: 1rem;
  }
`

const countryMap = {}
COUNTRIES.forEach((row) => {
  countryMap[row.id] = row.name
})

const Map = ({ data, scale, forExport }) => {
  const mapRef = useRef()
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const [isClient, setClient] = useState(false)
  const { t } = useTranslation()

  const [map, setMap] = useState()
  const [selectedId, setSelectedId] = useState('')
  const [tooltipData, setTooltipData] = useState(null)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  // Map layers can't be reused when there are different maps
  const vectorLayer = forExport ? EXPORT_VECTOR_LAYER : VECTOR_LAYER

  useEffect(() => {
    setClient(true)
  }, [])

  // Create map.
  useEffect(() => {
    if (isClient) {
      // To address issues with SSR
      const mapOptions = forExport
        ? EXPORT_MAP_OPTIONS
        : {
            ...MAP_OPTIONS,
            ...(isMobile ? MOBILE_OPTION_OVERRIDES : {})
          }
      const olMap = new OlMap(mapOptions)
      olMap.setTarget(mapRef.current)
      setMap(olMap)
      return () => olMap.setTarget(undefined) // Once the component unmounts, we dispose the map
    }
  }, [isClient, forExport, isMobile])

  // Set map styles
  useEffect(() => {
    if (data.length === 0) return

    const styleMap = {
      noData: new Style({
        fill: new Fill({ color: MAP_COLOR_PALETTE.NoData })
        // stroke: new Stroke({
        //   color: MAP_COLOR_PALETTE.NoData,
        //   width: 1
        // })
      }),

      ...scale.range().reduce((acc, color) => {
        return {
          ...acc,
          [color]: new Style({
            fill: new Fill({ color })
            // stroke: new Stroke({
            //   color: '#FFFFFF',
            //   width: 1
            // })
          })
        }
      }, {})
    }

    // Transform map data structure into an object for easier mapping of color values
    const mapData = {}
    data.forEach((d) => {
      mapData[d.COUNTRY] = d.VALUE_NUM
    })

    const style = (feature) => {
      const id = feature.get(FEATURE_PROP.Id) // Country id
      const value = mapData[id] ?? null
      const styleKey = value !== null ? scale(value) : 'noData'
      return styleMap[styleKey]
    }

    vectorLayer.setStyle(style)
  }, [data, scale, vectorLayer])

  // Generate tooltip data object
  const tooltipInfo = (inputData, id) => {
    const found = inputData.find((d) => d.COUNTRY === id)
    if (found) {
      return {
        label: found.COUNTRY_NAME,
        value: Math.round(found.VALUE_NUM)
      }
    }
    return { label: countryMap[id], message: 'No data' }
  }

  // Interactions with the map
  // Based on pixel position, finds country id
  const findFeatInfo = (pixel) => {
    vectorLayer.getFeatures(pixel).then((features) => {
      const feature = features?.[0]
      const id = feature?.get(FEATURE_PROP.Id) ?? null
      const isIdInRegion = data.find((d) => d.COUNTRY === id)
      if (id && Boolean(isIdInRegion)) {
        const tooltipValues = tooltipInfo(data, id)
        setSelectedId(id)
        setTooltipData(tooltipValues)
        setTooltipOpen(true)
      } else {
        setTooltipOpen(false)
      }
    })
  }

  useEffect(() => {
    if (map) {
      const onPointerMove = (e) => {
        if (e.dragging) return
        const pixel = map.getEventPixel(e.originalEvent)
        findFeatInfo(pixel)
      }
      map.on('pointermove', onPointerMove)
      return () => {
        map.un('pointermove', onPointerMove)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, data, selectedId])

  const MapTooltip = (data) => {
    if (data?.value) {
      return (
        <TooltipContainer>
          <div className="map-tooltip-heading">{t(data?.label)}</div>
          <div className="map-tooltip-value">{t(data?.value)}</div>
        </TooltipContainer>
      )
    }
    // No data tooltip
    return (
      <TooltipContainer>
        <div className="map-tooltip-heading">{data?.label}</div>
        <div className="map-tooltip-message">{t(data?.message)}</div>
      </TooltipContainer>
    )
  }

  return (
    <Tooltip
      html={MapTooltip(tooltipData)}
      position="bottom"
      trigger="manual"
      open={tooltipOpen}
      followCursor={true}
      arrow={true}
      arrowSize="regular"
      animation="none"
      theme="light"
    >
      {isClient && <Container ref={mapRef} />}
    </Tooltip>
  )
}

export default Map
